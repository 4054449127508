import 'bootstrap/js/dist/modal';
import {getScrollbarWidth} from "./utils";

export function init () {
    $(document.body).on('show.bs.modal', modalShownHandler);
}

function modalShownHandler() {
    let scrollbarWidth = getScrollbarWidth();

    let $body = $(document.body);
    $body.css('--fixed-offset-right', `${scrollbarWidth}px`);

    $body.one('hidden.bs.modal', () => {
        $body.css('--fixed-offset-right', '');
    });
}