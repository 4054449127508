"use strict";
import fetch from '@elements/fetch';
import {debounce} from "debounce";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope ($scope) {
    let url;
    let id;
    let element;

    if(!window.matchMedia("(pointer: coarse)").matches) {
        $scope.find('.js-color-hover__link').on('mouseenter', debounce(function (e) {
            url = $(this).attr('data-color-data');
            id = $(this).attr('data-productid');
            element = $(this);
            let parentId = element.closest('.js-color-hover').attr('data-productid');

            /* load data only if not default product */
            if(id !== parentId) {
                loadColorData(url, element);
                element.closest('.js-color-hover').addClass('color-changed');
            }

            /* load default data if new color was already loaded */
            if(id == parentId && element.closest('.js-color-hover').hasClass('color-changed')) {
                loadColorData(url, element);
                element.closest('.js-color-hover').removeClass('color-changed');
            }
        }, 150));

        $scope.find('.js-color-hover').on('mouseleave', function (e) {
            url = $(this).attr('data-color-default-data');
            element = $(this);

            if(element.hasClass('color-changed')) {
                /* wait until zoom animation has finished, then reload data */
                setTimeout(function() {
                    loadColorData(url, element);
                    element.removeClass('color-changed');
                }, 100);
            }
        });
    }
}



function loadColorData(url, element) {
    let $this = element;
    let $teaser = $this.closest('.js-color-hover');
    let $image = $this.closest('.js-color-hover').find('.js-color-hover__img');
    let $title = $this.closest('.js-color-hover').find('.js-color-hover__title');
    let $price = $this.closest('.js-color-hover').find('.js-color-hover__price');
    let $sizes = $this.closest('.js-color-hover').find('.js-color-hover__sizes');
    let $btn = $this.closest('.js-color-hover').find('.js-color-hover__href-btn');
    let $link = $this.closest('.js-color-hover').find('.js-color-hover__link');

    let pendingRequest;

    if(pendingRequest) {
        pendingRequest.abort();
        pendingRequest = null;
    }

    pendingRequest = fetch(url, {
        method: 'GET'
    });

    pendingRequest.then((res) => {
        return res.clone().json()
    }).then((res) => {
        if(res.success){
            if(res.title){
                $title.html(res.title);
            }
            if(res.maintitle){
                $btn.html(res.maintitle);
            }
            if($price && res.price){
                $price.html(res.price);
            }
            if($image && res.image){
                $image.fadeOut("200");
                setTimeout(function() {
                    $image.html(res.image);
                    initModulesInScope($image);
                    $image.fadeIn("150");
                }, 100);
            }
            if($sizes && res.sizes){
                $sizes.html(res.sizes);
            }
            if(res.href){
                $btn.attr('href',res.href);
                $link.attr('href',res.href);
            }

            if($sizes.length){initModulesInScope($sizes);}

        } else {
            //console.log(res);
        }
    })
}
