"use strict";

export function init() {
    $('.js-toggle-nav').on('click', function (evt) {
        evt.preventDefault();
        $(this).addClass('pointer-events-none');

        let $mainNav = $(this).closest('.navbar-container');

        if(!$mainNav.hasClass('is-open')) {
            $mainNav.addClass('is-open');
            $(this).addClass('is-open');
            $('html').addClass('overflow-hidden');
            $('#headerSearch').removeClass('is-open');
        } else {
            $mainNav.removeClass('is-open');
            $(this).removeClass('is-open');
            $('html').removeClass('overflow-hidden');
        }

        setTimeout(function() {
            $('.js-toggle-nav').removeClass('pointer-events-none');
        }, 500);
    });


    /*toggle genderswitch
    $('.js-toggle-gender__item').on('click', function (evt) {
        if($(this).hasClass('nav-genderswitch-dropdown__item--active')) {
            evt.preventDefault();
        }

        let $genderSwitch = $(this).closest('.js-toggle-gender');

        if(!$genderSwitch.hasClass('nav-genderswitch-dropdown--open')) {
            $genderSwitch.addClass('nav-genderswitch-dropdown--open');
        } else {
            $genderSwitch.removeClass('nav-genderswitch-dropdown--open');
        }
    });*/
}