"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope($scope) {

    if($(document.documentElement).hasClass('matomoHsr')) {
        renderAllTemplates($scope);
    }

    onEnterViewPort($scope.find('.js-render-template'), function () {
        renderTemplate($(this));
    }, { offset: 500 });


    if (matchMedia('(min-width: 768px)').matches) {
        let $items = $scope.find('.js-touch-nav__item');
        $items.on('mouseenter', function() {
            renderChildTemplates($(this), '.js-render-navbar-template','.js-render-navbar-template__template');
        });
    }

}

export function renderChildTemplates($parent, templateSelector, templateChildSelector) {
    let $renderNavbarContainer = $parent.find(templateSelector);

    $renderNavbarContainer.each(function () {
        renderTemplate($(this), templateChildSelector)
    });
}

export function renderTemplate($renderTemplate, templateChildSelector = '.js-render-template__template') {
    let $template = $renderTemplate.find(templateChildSelector);
    let templateHtml = $template.html();

    if (templateHtml) {
        $renderTemplate.html(templateHtml);
    }
    $template.remove();
    initModulesInScope($renderTemplate);
}

export function renderAllTemplates($scope) {
    $scope.find('.js-render-template').each(function() {
        renderTemplate($(this));
    })
}
