import initModulesInScope from "@elements/init-modules-in-scope";

// todo error handling
// todo fallback for not supported IEs

let elements = [];
window.elements = elements;

export function init() {
    if (!supportsTemplates()) {
        return;
    }

    let $newElements = $('.js-matchmedia-viewer');
    addElements($newElements);

    // todo debounce
    $(window).on('resize', function () {
        processElements(elements);
    });
}

export function initInScope($scope) {
    if (!supportsTemplates()) {
        return;
    }

    let $newElements = $scope.find('.js-matchmedia-viewer');
    addElements($newElements);
}

function addElements($newElements) {
    let newElements = $newElements.toArray()
        .filter(element => !$(element).data('matchmedia-viewer-is-added'))
        .map((element) => {
            let $container = $(element);
            let isTemplate = $(element).is('template');

            if (isTemplate) {
                $container = $('<div></div>');
                $(element).after($container)
            }

            $(element).data('matchmedia-viewer-is-added', true);

            return {
                content: isTemplate ? $('<div></div>').append($(element.content)).html() : $(element).html(),
                $container: $container,
                media: $(element).data('matchmedia'),
                isCurrentlyEmbedded: !isTemplate
            };
        });

    processElements(newElements);
    elements = elements.concat(newElements);
}

function processElements(elements) {
    elements.forEach(function (element) {
        if (matchMedia(element.media).matches) {
            if (!element.isCurrentlyEmbedded) {
                element.isCurrentlyEmbedded = true;
                element.$container.empty().append($(element.content));
                show(element.$container);
                initModulesInScope(element.$container);
            }
        } else {
            if (element.isCurrentlyEmbedded) {
                element.isCurrentlyEmbedded = false;
                element.$container.empty();
                hide(element.$container)
            }
        }
    });
}


function hide($element) {
    $element.attr('hidden', 'hidden').addClass('hidden');
}

function show($element) {
    $element.attr('hidden', null).removeClass('hidden');
}

function supportsTemplates() {
    return 'content' in document.createElement('template');
}
