import copy from 'copy-to-clipboard';
import {showNotification} from '@elements/alert-notification';
import {translate} from '@elements/translations';

export function initInScope($scope) {
    $scope.find('.js-copy-to-clipboard').each(function () {
        let $container = $(this);
        let $button = $container.find('.js-copy-to-clipboard__btn');
        let $input = $container.find('.js-copy-to-clipboard__input');

        $input.on('focus', function () {
            $input[0].setSelectionRange(0, $input.val().length);
        })

        $button.on('click', function () {
            copy($input.val());

            showNotification({
                type: 'success',
                content: translate('copy-to-clipboard.notification')
            })
        });
    });
}
