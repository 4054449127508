'use strict'

let storageKey = 'visitorInfo';

export function init() {

    if (window.localStorage && _config.getVisitorInfo) {

        $.each(JSON.parse(_config.getVisitorInfo), function (key, value) {
            updateStorage(key, value);
        });

        updateStorage('userCountryCode', _config.detectedCountryCode);
        //updateStorage('userCountryCode', 'DE');
        updateStorage('storeByCountry', _config.detectedCountryLink);
        checkModal();

        $('.js-choose-country').on('click', function (evt) {
            $('#modal-country-switcher').modal('hide');
            $('#modal-language-switcher').modal('show');
        });

    }
}

function checkModal() {

    var date = new Date();
    var timestamp = Math.round(date.getTime() / 1000);
    let mins = 180; // 3 minutes
    let visitorInfo = getVisitorInfo();
    let lastSiteRequest = parseInt(visitorInfo.lastSiteRequest);

    if (!lastSiteRequest) {
        lastSiteRequest = 0;
    }

    let requestTime = lastSiteRequest + mins;

    // user is on the right country
    if (_config.countryCode == visitorInfo.detectedValidCountryCode) {
        updateStorage('countryInfoModal', true);
    }

    // referrer is not same Site
    if (!visitorInfo.sameSiteRequest) {

        if (requestTime < timestamp) {
            if (_config.countryCode != visitorInfo.detectedValidCountryCode) {
                openModal(timestamp);
                updateStorage('countryInfoModal', true);
            }
        } else {
            if (_config.countryCode != visitorInfo.detectedValidCountryCode && visitorInfo.storeByCountry) {
                if (!visitorInfo.countryInfoModal) {
                    // under 3 mins do redirect
                    window.location = visitorInfo.storeByCountry;
                }
            }
        }

    } else if (!visitorInfo.countryInfoModal) {
        if (_config.countryCode != visitorInfo.detectedValidCountryCode) {
            openModal(timestamp);
            updateStorage('countryInfoModal', true);
        }
    }

}

function openModal(timestamp) {

    let visitorInfo = getVisitorInfo();

    if(!visitorInfo.countryInfoModal){
        // 2. Jemand ist in Deutschland und ruft eine Variante mit Shop auf (zB /de_at) --> Shopauswahl öffnet sich (country_switcher.html.twig)
        if (visitorInfo.storeByCountry && visitorInfo.storeByCountry != 'null' && visitorInfo.lastVisitCountryHasStore && visitorInfo.userCountryHasStore) {
            // show modal with store
            $('#modal-country-switcher').modal('show');
        } else {


            // 5. Jemand ist im Ausland das wir nicht kennen (Variante nicht vorhanden) und ruft eine Variante mit Shop auf --> Language Switcher öffnet sich ( =3.)
            if (visitorInfo.detectedValidCountryCode != visitorInfo.userCountryCode && visitorInfo.lastVisitCountryHasStore) {
                // show general modal
                $('#modal-language-switcher').modal('show');
            }

            // 3. Jemand ist im Ausland das wir kennen und ruft eine Variante mit Shop auf (zB /de_de) --> Language Switcher mit Zusatztext öffnet sich
            if (visitorInfo.detectedValidCountryCode == visitorInfo.userCountryCode && visitorInfo.lastVisitCountryHasStore) {
                // show general modal
                $('#modal-language-switcher').modal('show');
            }

            // 1. Jemand ist in Deutschland und ruft eine Variante ohne Shop auf (zB /fr_fr) --> Language Switcher mit Zusatztext öffnet sich
            if (visitorInfo.detectedValidCountryCode == visitorInfo.userCountryCode && !visitorInfo.lastVisitCountryHasStore) {
                // show general modal
                $('#modal-language-switcher').modal('show');
                if(visitorInfo.lastVisitCountry == 'INT'){
                    $('.js-language-modal-placeholder-int').removeAttr('hidden');
                } else {
                    $('.js-language-modal-placeholder').removeAttr('hidden');
                }

                $('#modal-language-switcher').on('hidden.bs.modal', function () {
                    $('.js-language-modal-placeholder').attr('hidden', 'hidden');
                    $('.js-language-modal-placeholder-int').attr('hidden', 'hidden');
                })
            }

        }
    }


    updateStorage('lastSiteRequest', timestamp);

}


function updateStorage(key, value) {

    let visitorInfo = localStorage.getItem(storageKey);
    var data = JSON.parse(visitorInfo);

    if(!data){
        data = {}
    }

    if (key && value != 'null') {
        data[key] = value;
    }

    localStorage.setItem(storageKey, JSON.stringify(data));

}

function getVisitorInfo() {
    let visitorInfoData = localStorage.getItem(storageKey);
    return JSON.parse(visitorInfoData);
}