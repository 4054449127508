'use strict'
export function initInScope($scope) {
    let hideBadge = _config.hideTrustBadge;
    let $placeholder = $scope.find('.js-trust-badge-placeholder');
    let $trust = $scope.find('.js-trust-badge');

    if(hideBadge) {
        $trust.attr('hidden','hidden');
    }

    if (matchMedia('(min-width: 768px)').matches) {
        function scrollDetect(){
            let lastScroll = 0;
            let $badge = $('.js-trust-badge');
            // window.onscroll = function() {
            document.addEventListener('scroll', function(){
                let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
                if (currentScroll > 0 && lastScroll <= currentScroll){
                    lastScroll = currentScroll;
                    $badge.addClass('hide-trust-badge');
                }else{
                    lastScroll = currentScroll;
                    $badge.removeClass('hide-trust-badge');
                }
            });
        }

        scrollDetect();

        /* move badge into modal */
        $('#trustModal').on('shown.bs.modal', function (e) {
            $trust.appendTo($placeholder);
            $trust.removeClass('trust-badge');
            if(hideBadge) {
                $trust.attr('hidden',false);
            }
        });

        $('#trustModal').on('hidden.bs.modal', function (e) {
            $trust.appendTo($('body'));
            $trust.addClass('trust-badge');
            if(hideBadge) {
                $trust.attr('hidden','hidden');
            }
        });
    }
}
