"use strict";

export function initInScope ($scope) {
    let $button = $scope.find('.js-href-btn');

    $button.on('click', function (e) {
        e.preventDefault();

        //if not class js-newsletter-modal-btn
        if (!$(this).hasClass('js-newsletter-modal-btn')) {
            window.location.href = $(this).attr('data-href');
        }
    });
}