"use strict";

export function initInScope ($scope) {
    $scope.find('.js-newsletter-category').on('click',function () {
        let $newsletterCategory = $(this).find('.js-newsletter-category__checkbox');

        if ($newsletterCategory.prop('checked')) {
            $newsletterCategory.prop('checked', false);
        } else {
            $newsletterCategory.prop('checked', true);
        }
    });
}