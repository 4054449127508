'use strict';
import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import {isParsleyForm, isValid} from "@elements/parsley-bootstrap-validation";

export function initInScope($scope) {
    let $registrationForm = $scope.find('.js-registration-form');

    $registrationForm.on('submit', function(e, forceSubmit) {
        let $checkbox = $registrationForm.find('#registrationForm_newsletterConsent');
        let newsletterState = false;

        if ($checkbox.prop('checked')) {
            newsletterState = true;
        }

        if (typeof e.originalEvent === 'undefined' && !forceSubmit) {
            // prevent parsley from firing twice submit twice (due to promise validator)
            return false;
        }

        if (isParsleyForm($registrationForm) && isValid($registrationForm)) {
            dataLayer.push({
                'event': 'sign_up',
                'newsletter_checkbox': newsletterState
            });
        }
    });
}

