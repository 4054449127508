"use strict";

import {getScrollbarWidth} from "./utils";

const BACKDROP_CLASS = 'modal-backdrop';
let $backdrop;

let isOpen;
let currentScrollPosition = 0;
let collapsesOpen = []; //array which saves all opened collapses
let lastChangedCollapse; //collapse which is changed last
let offsetOfLastChangedCollapse = 0;
let isMainCollapseOpen = true;

export function initInScope ($scope) {
    let $offscreen = $scope.find('.js-toggle-offscreen');
    let $loading = $scope.find('.js-toggle-offscreen__loading');
    let $scrollBody = $('.js-toggle-offscreen__scroll-body');
    let $collapses = $('.js-toggle-offscreen__collapse');
    let isInProductGrid = $offscreen.parents('.js-product-grid__form').length;

    $offscreen.on('click', function (evt) {
        evt.preventDefault();

        if($(this).hasClass('js-toggle-offscreen--additional-filter')) {

            let $collapseFilterElement = $(this).attr('data-filter-collapse');
            let $element = $($collapseFilterElement);
            let $scrollParent = $scrollBody;
            let height = $element.height();
            /* close all filter */
            $('.offscreen-filter .collapse:not('+$collapseFilterElement+')').collapse('hide');
            $($collapseFilterElement).collapse('show');
            if($($collapseFilterElement).hasClass('show')) {
                $element.css('display', 'block');
                $element.css('display', '');
                $($collapseFilterElement).collapse('show');
            }

            setTimeout(function(){
                let height = $element.height();
                let delta = ($element.offset().top + height) - ($scrollParent.height() + $scrollParent.offset().top);
                $scrollParent.animate({scrollTop:$scrollParent[0].scrollTop + delta + 300}, {duration: 280});
            }, 500);
        }

        if($(this).hasClass('js-toggle-offscreen--main')) {
            /* close all filter */
            $('.offscreen-filter .collapse:not(.js-toggle-offscreen__collapse--main-filter)').collapse('hide');

            $('#mainFilters').collapse('show');
            let $element = $('#collapseSorting');
            let $scrollParent = $scrollBody;
            let height = $element.height();
            if($('#mainFilters').hasClass('show')) {
                $element.css('display', 'block');
                $element.css('display', '');
            }
            setTimeout(function(){
                let delta = ($element.offset().top + height) - ($scrollParent.height() + $scrollParent.offset().top);
                $scrollBody.animate({scrollTop:$scrollBody.offset().top + delta - 30}, {duration: 280});
            }, 500);
        }

        /*wird immer benötigt*/
        if($(this).hasClass('js-toggle-offscreen--sort')) {
            $('#collapseSorting').collapse('show');
            if($('#collapseSorting').hasClass('show')) {
                let $element = $('#collapseSorting');
                let $scrollParent = $scrollBody;
                $element.css('display', 'block');
                let height = $element.height();
                $element.css('display', '');

                let delta = ($element.offset().top + height) - ($scrollParent.height() + $scrollParent.offset().top);
                $scrollParent.animate({scrollTop:$scrollParent[0].scrollTop + delta + 10}, {duration: 280});
            }
        }

        let $this = $(this);
        let target = $this.attr('href') || $this.data('target');
        let $target = $(target);

        if(!$target.hasClass('is-open')) {
            evt.stopPropagation();
            showOverlay($target, $loading);
        } else {
            hideOverlay($target, $loading);
        }
    });

    if (isInProductGrid && isOpen) {
        $($offscreen.attr('href')).addClass(['is-open','no-transition']);
    }

    if (isInProductGrid) {
        let $inputs = $scrollBody.find('input');

        // save current scrollPosition before product-grid-filters are reloaded
        $scrollBody.on('scroll', function(event) {
            if (matchMedia('(min-width: 768px)').matches) {
                return;
            }

            currentScrollPosition = event.target.scrollTop;
        });

        $collapses.on('show.bs.collapse', function() {
            if (matchMedia('(min-width: 768px)').matches) {
                return;
            }

            // scroll to element if not in viewport
            let $element = $(this);
            let $scrollParent = $scrollBody;

            $element.css('display', 'block');
            let height = $element.height();
            $element.css('display', '');

            let timeout = setTimeout(function () {
                let delta = ($element.offset().top + height) - ($scrollParent.height() + $scrollParent.offset().top);
                if (delta > 0) {
                    $scrollParent.animate({scrollTop:$scrollParent[0].scrollTop + delta + 10}, {duration: 280});
                }
                clearTimeout(timeout);
            }, 0);

            // save all opened collapse-filters in array before product-grid-filters are reloaded
            collapsesOpen.push(`#${$(this).attr('id')}`);

            if($(this).hasClass('js-toggle-offscreen__collapse--main-filter')) {
                isMainCollapseOpen = true;
            }
        });

        // remove closed collapse-filters from array
        $collapses.on('hide.bs.collapse', function() {
            if (matchMedia('(min-width: 768px)').matches) {
                return;
            }

            $(this).removeClass('no-transition');

            collapsesOpen.splice($.inArray(`#${$(this).attr('id')}`, collapsesOpen), 1);

            if($(this).hasClass('js-toggle-offscreen__collapse--main-filter')) {
                isMainCollapseOpen = false;
            }
        });

        // save which collapse hast changed last, to get new scroll-position after reload
        $inputs.on('change', function(event) {
            if (matchMedia('(min-width: 768px)').matches) {
                return;
            }

            let $collapseWrapper =  $(this).closest('.js-toggle-offscreen__collapse');
            let collapseId = $collapseWrapper.attr('id');
            lastChangedCollapse = `#${collapseId}`;
            offsetOfLastChangedCollapse = $collapseWrapper[0].getBoundingClientRect().top;
        });

        $('.js-product-grid').on('product-grid.success', function (event) {
            if (matchMedia('(min-width: 768px)').matches) {
                return;
            }

            // if product-grid is reloaded open all collapses from array (collapsesOpen)
            collapsesOpen = [... new Set(collapsesOpen)];
            collapsesOpen.forEach(function(collapseElement) {
                $(collapseElement).addClass('no-transition').collapse('show');
            });

            if(!isMainCollapseOpen) {
                $('.js-toggle-offscreen__collapse--main-filter').addClass('no-transition').collapse('hide');
            }

            // get new scrollPosition after reload
            let lastChangedCollapseTemp = $scrollBody.find(lastChangedCollapse)[0];

            let newOffset = (typeof lastChangedCollapseTemp === 'undefined') ? 0 : lastChangedCollapseTemp.getBoundingClientRect().top - offsetOfLastChangedCollapse;

            $scrollBody.scrollTop(currentScrollPosition + (newOffset - currentScrollPosition));
        });
    }
}

export function showOverlay($overlay, $loading) {
    $overlay.addClass('is-open');
    isOpen = true;
    showBackdrop();
    addScrollbarOffset();
    $('html').addClass('overflow-hidden');
    if ($loading) {
        $loading.attr('hidden',false);
    }

    $(document.body)
        .on('click', bodyClickHandler)
        .on('show.bs.modal', modalShownHandler);
}

export function isOverlayOpen($overlay) {
    return $overlay.hasClass('is-open');
}

export function hideOverlay($overlay, $loading) {
    $overlay.removeClass(['is-open','no-transition']);
    isOpen = false;
    removeBackdrop();
    $('html').removeClass('overflow-hidden');
    if ($loading) {
        $loading.attr('hidden','hidden');
    }
    restScrollbarOffset();

    $(document.body)
        .off('click', bodyClickHandler)
        .off('show.bs.modal', modalShownHandler);
}

function showBackdrop() {
    $backdrop = $('<div>', {'class': BACKDROP_CLASS});
    $backdrop.addClass('fade');
    $backdrop.addClass('show');
    $backdrop.appendTo(document.body);
}

function removeBackdrop() {
    $backdrop.remove();
    $backdrop = null;
}

function bodyClickHandler (evt) {
    if ($(evt.target).closest('.js-offscreen').length < 1
        && $(evt.target).closest('.js-toggle-offscreen--prevent-bodyclick').length < 1
        && $(evt.target).closest('.js-alert-notification').length < 1
    ) {
        evt.preventDefault();
        hideOverlay($('.js-offscreen'));
        $('body').off('click', bodyClickHandler);
    }
}

function modalShownHandler() {
    let $body = $(document.body);
    $body.off('click', bodyClickHandler);
    $body.one('hidden.bs.modal', () => {
        $body.on('click', bodyClickHandler);
        addScrollbarOffset();
    });
}

function addScrollbarOffset() {
    let scrollbarWidth = getScrollbarWidth();

    $(document.body)
        .css('padding-right', scrollbarWidth)
        .css('--fixed-offset-right', `${scrollbarWidth}px`);
}

function restScrollbarOffset() {
    $(document.body)
        .css('padding-right', '')
        .css('--fixed-offset-right', '');
}
