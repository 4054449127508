'use strict'
import loadScript from "@elements/load-script";

export function initInScope($scope) {
    let $awsChallengeForms = $scope.find('.js-aws-challenge');

    if ($awsChallengeForms.length && (_config.environment === 'prod' || _config.environment === 'preprod')) {
        const scriptUrls = {
            'preprod': 'https://60cd085d905f.edge.sdk.awswaf.com/60cd085d905f/67fb9622d28f/challenge.js',
            'prod': 'https://d05ea2e97306.edge.sdk.awswaf.com/d05ea2e97306/9458ff1d416a/challenge.js'
        };

        let scriptUrl = scriptUrls[_config.environment];

        $awsChallengeForms.each(function () {
            let $form = $(this);
            let $submitBtn = $form.find('[type=submit]');
            let $additionalSubmitBtn = $scope.find('.js-aws-challenge__additional-button');

            // disable the submit button(s) until the script is loaded
            $submitBtn.attr('disabled','disabled');

            if($additionalSubmitBtn.length) {
                $additionalSubmitBtn.attr('disabled','disabled');
            }

            // load the script
            loadScript(scriptUrl).then(function () {
                // if there's no token, disable the submit buttons until we get one
                if(!AwsWafIntegration.hasToken()) {

                    // now we need to get a token
                    AwsWafIntegration.getToken()
                        .then(token => {
                            // now we have a token, enable the submit buttons
                            $submitBtn.removeAttr('disabled');
                            if($additionalSubmitBtn.length) {
                                $additionalSubmitBtn.removeAttr('disabled');
                            }
                        })
                        .catch(e => {
                            console.log('error', e);
                        })
                } else {
                    // if there already is a token, enable the submit buttons
                    $submitBtn.removeAttr('disabled');
                    if($additionalSubmitBtn.length) {
                        $additionalSubmitBtn.removeAttr('disabled');
                    }
                }
            });
        });
    }
}
