'use strict'

import {loadAjaxModal} from './ajaxModal';

export function init() {
    const $loading = $('.js-modal__loading');

    let openNewsletterModal = () => {
        let url = _config.newsletterModalUrl;
        let $modal = $(_config.newsletterModalTarget);
        let $modalContent = $modal.find('.js-ajax-modal-content');
        let $modalNotifications = $modal.find('.js-ajax-modal__notifications');

        loadAjaxModal(url, $loading, $modalContent, $modalNotifications)
        $modal.modal();
    }

    if (_config.newsletterModal) {
        openNewsletterModal();
    }

    $('.js-newsletter-modal-btn').on("click", (e) => {
        openNewsletterModal();
        e.preventDefault();
    });
}