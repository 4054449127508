"use strict";

const selectors = {
    video: '.js-play-video__video',
    overlay: '.js-play-video__overlay',
    base: '.js-play-video'
};

export function initInScope ($scope) {
    // changes the content of the target element
    let $videoAreas = $scope.find(selectors.base);

    $videoAreas.on('click', function () {
        let $this = $(this);
        let $overlay = $this.find(selectors.overlay);
        let $video = $this.find(selectors.video);

        $video[0].play();
        $overlay.attr('hidden', true);
    });

}
