'use strict';

import {trackEvent} from "@elements/tracking/src/gtm";

export function initInScope($scope) {

    if($scope.find('.js-newsletterSubmit').length >= 1)
    {
        let $form = $scope.find('.js-newsletterSubmit');

        $form.on('failed.ajax-form success.ajax-form', (e) => {
            $form.closest('.modal').modal('hide');
            trackEvent({category: 'Newsletter',
                        action: 'Submit',
                        label: location.href},
                    {debug: true});
        });
    }
}