"use strict";

import $ from "jquery";
import {renderChildTemplates} from "./renderTemplate";

const defaultSelectors = {
    base: '.js-touch-nav',
    item: '.js-touch-nav__item'
};

export function createInitInScope(selectors = defaultSelectors) {

    if(matchMedia('(max-width: 767px)').matches){
        /* not on mobile */
        return;
    }

    return function ($scope) {
        return $scope.find(selectors.base).each(function () {
            let $touchNav = $(this);
            let $touchNavItems = $touchNav.find(selectors.item);
            let $touchNavItemsLinks = $($touchNavItems.children());

            $touchNavItemsLinks.on('touchend', function (evt) {
                if (window.TouchEvent && evt.originalEvent instanceof TouchEvent) {
                    if (!$(this).closest(defaultSelectors.item).hasClass('is-open')) {
                        $(this).closest(defaultSelectors.base).find('li').removeClass('is-open');
                        $(this).closest(defaultSelectors.item).addClass('is-open');

                        evt.preventDefault();
                        evt.stopImmediatePropagation();

                        $('body').on('click', bodyClickHandler);

                        // render navbar-templates
                        renderChildTemplates($(this).closest(defaultSelectors.item), '.js-render-navbar-template','.js-render-navbar-template__template');
                    }
                }

            });
        });
    }
}

function bodyClickHandler (evt) {
    if ($(evt.target).parents(defaultSelectors.base).length < 1) {
        evt.preventDefault();
        $(defaultSelectors.item).removeClass('is-open');
        $('body').off('click', bodyClickHandler);
    }
}

export const initInScope = createInitInScope();
