"use strict";
export function initInScope ($scope) {
    function isIE() {
        let userAgent = navigator.userAgent;
        let ie = userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;
        return ie;
    }
    if (isIE()){
        $scope.find('.js-ie-modal__close').on('click', function () {
            localStorage.setItem('ieModal', true);
        });

        if (!localStorage.getItem('ieModal')) {
            setTimeout(function(){
                console.log('It is not Internet Explorer');
                $('#ie-modal').modal('show');
            }, 4000);
        }
    }
}