"use strict";
import debounce from 'debounce';

export function initInScope($scope) {
    let scrollWrapper = $scope.find('.js-scroll-indicator');

    scrollWrapper.each(function() {
        let wrapper = $(this);
        let scrollArea = wrapper.find('.js-scroll-indicator__scroll-area');

        if (!scrollArea) {
            console.warn(`.js-scroll-indicator is missing a child with the class '.js-scroll-indicator__scroll-area'`, scrollArea);
            return;
        }

        // check if scrollable : scrollArea.scrollWidth > scrollArea.clientWidth
        setVisibility();

        function setVisibility() {
            // check if area is horizontally scrollable or scrolled to the max
            if (scrollArea[0].clientWidth + scrollArea[0].scrollLeft < scrollArea[0].scrollWidth) {
                wrapper.addClass('scroll-indicator--visible');
            } else {
                wrapper.removeClass('scroll-indicator--visible');
            }
        }

        let lastScrollPosition = scrollArea[0].scrollLeft;
        function interval() {
            if (lastScrollPosition === scrollArea[0].scrollLeft) {
                requestAnimationFrame(interval);
                return;
            }

            lastScrollPosition = scrollArea[0].scrollLeft;
            setVisibility();

            requestAnimationFrame(interval);
        }

        requestAnimationFrame(interval);

        $(window).on('resize', debounce(() => {
            lastScrollPosition = Number.NEGATIVE_INFINITY;
        }, 200));
    })

}