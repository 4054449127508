"use strict";

export function initInScope($scope) {
    let $showCookieSettingsBtns = $scope.find('.js-show-cookie-settings');
    $showCookieSettingsBtns.on('click', function (evt) {
        evt.preventDefault();
        if (window.UC_UI && window.UC_UI.isInitialized()) {
            UC_UI.showSecondLayer();
        }
    });
}