'use strict';

export function initInScope($scope) {
    let $navLink = $scope.find('.js-track-nav-link');

    $navLink.off('click').on('click', function(e) {
        let clickItem = $(this).text();
        let clickUrl = $(this).attr('href');
        let level = $(this).attr('data-level');

        dataLayer.push({
            'event': 'navigation',
            'nav_level': level,
            'click_item': clickItem.trim(),
            'click_url': clickUrl
        });
    });
}

