"use strict";

export function initInScope ($scope) {
    $scope.find('.js-newsletter-categories__main').on('change',function () {
        let $newsletterCategories = $(this).closest('.js-newsletter-categories').find('.js-newsletter-categories__sub');

        if ($(this).prop('checked')) {
            $newsletterCategories.removeAttr('hidden');
        } else {
            $newsletterCategories.attr('hidden','hidden');
        }
    });
}