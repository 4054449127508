"use strict";

export function initInScope ($scope) {
    $scope.find('.js-file-input').on('change', function () {
        let $element = $(this),
            $wrapper = $element.closest('.js-file-input-wrapper'),
            $input = $element.find(':file'),
            $label = $element.find('.js-file-input__text'),
            numFiles = $input.get(0).files ? $input.get(0).files.length : 1,
            label = $input.val().replace(/\\/g, '/').replace(/.*\//, '');

        if (numFiles === 0) {
            label = $element.data('file-input-default-text');
            $wrapper.removeClass('has-file');
        } else if (numFiles === 1) {
            $wrapper.addClass('has-file');
        } else if (numFiles > 1) {
            label = $element.data('file-input-multiple-text').replace('[count]', numFiles);
            $wrapper.addClass('has-file');
        }

        $label.text(label);
    });


    $scope.find('.js-file-input-delete__btn').on('click', function () {
        let $element = $(this).closest('.js-file-input'),
            $inputGroup = $element.closest('.js-file-input-wrapper'),
            $input = $element.find(':file'),
            $label = $element.find('.js-file-input__text')

        $input.val('');
        $inputGroup.removeClass('has-file')
        $label.text($element.data('file-input-default-text'));
    });
}
