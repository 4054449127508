"use strict";

export function initInScope ($scope) {

    $scope.find('.js-usps-flyout__link').on('click', function (evt) {
        evt.preventDefault();

        let $flyout = $scope.find('.js-usps-flyout');

        if(!$flyout.hasClass('usps-flyout--open')) {
            $flyout.addClass('usps-flyout--open');
        } else {
            $flyout.removeClass('usps-flyout--open');
        }
    });
}