"use strict";

export function initInScope ($scope) {
    $scope.find('.js-loyalty-membership').on('change',function () {
        let $loyaltyConsentCheckbox = $(this).closest('form').find('.js-loyalty-consent');

        if ($(this).val() === '1') {
            $loyaltyConsentCheckbox.removeClass('loyalty-consent--hidden');
        } else {
            $loyaltyConsentCheckbox.addClass('loyalty-consent--hidden');
        }
    });
}