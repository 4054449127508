"use strict";

export function initInScope ($scope) {

    $scope.find('.js-collapse-details__select').on('change',function (e) {
        let toggleElSelector = $(this).find('option:selected').data('collapse-target');
        let $detailContainers = $(this).closest('.js-collapse-details').find('.js-collapse-details__detail');

        /* close all */
        $detailContainers.collapse('hide');

        /* open selected */
        if (toggleElSelector) {
            let $toggleEl = $(this).closest('.js-collapse-details').find(toggleElSelector);
            if ($toggleEl.length) {
                if ($toggleEl.hasClass('show')) {
                    $toggleEl.collapse('hide');
                } else {
                    $toggleEl.collapse('show');
                }
            }
        }
    });
}