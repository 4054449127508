"use strict";


const selectors = {
    base: '.js-hotspot',
    toggle: '.js-hotspot__toggle',
    content: '.js-hotspot__content',
    loading: '.js-hotspot__loading'
};

const classes = {
    open: 'is-open'
};

let $body = $('body');
let pendingRequest;

export function initInScope ($scope) {
    //toggle hotspots
    $scope.find(selectors.toggle).on('click', function (evt) {
        evt.preventDefault();

        let $hotspot = $(this).closest(selectors.base);

        if(!$hotspot.hasClass(classes.open)) {
            $(selectors.base).each(function () {
                $(this).removeClass(classes.open);
            });

            // if(pendingRequest) {
            //     pendingRequest.abort();
            //     pendingRequest = null;
            // }

            // request
            // let ajaxUrl = $hotspot.data('ajax-url');
            // if (ajaxUrl) {
            //     pendingRequest = fetch(ajaxUrl, {
            //         headers: {
            //             'pragma': 'no-cache',
            //             'cache-control': 'no-cache',
            //         }
            //     });
            //
            //     asyncAppend({
            //         $target: $hotspot.find(selectors.content),
            //         $loading: $hotspot.find(selectors.loading)
            //     }, pendingRequest)
            //
            // }

            $hotspot.addClass(classes.open);
            $body.on('click', bodyClickHandler);
        } else {
            $hotspot.removeClass(classes.open);

            $body.off('click', bodyClickHandler);
        }
    });

    //hotspot detail box position
    $scope.find(selectors.base).each(function () {
        let $this = $(this),
            $content = $this.find(selectors.content),
            $container = $this.closest('.js-hotspot-container');

        new Popper($this, $content, {
            placement: 'top',
            modifiers: {
                preventOverflow: {boundariesElement: $container[0]},
            },
        });
        // if(container.length) {
        //     let hotspotHeight = $this.outerHeight()  + $this.find('.hotspot__detail').outerHeight(),
        //         hotspotWidth = $this.outerWidth() / 2 + $this.find('.hotspot__detail').outerWidth() / 2;
        //
        //     if($this.offset().top - container.offset().top + hotspotHeight > container.outerHeight()) {
        //         $this.addClass('hotspot--detail-top');
        //     }
        //
        //     if ($this.offset().left + hotspotWidth > container.outerWidth()) {
        //         $this.addClass('hotspot--detail-left');
        //     }
        //
        //     if ($this.offset().left - hotspotWidth < 0) {
        //         $this.addClass('hotspot--detail-right');
        //     }
        // }
        //
        // if(matchMedia('(min-width: 768px)').matches) {
        //
        // }
    });
}

function bodyClickHandler (evt) {
    if ($(evt.target).parents('.js-hotspot').length < 1) {
        evt.preventDefault();
        $(selectors.base).removeClass(classes.open);

        $body.off('click', bodyClickHandler);
    }
}