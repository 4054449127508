"use strict";

import {renderChildTemplates} from "./renderTemplate";

export function initInScope ($scope) {
    let $navItemsLinks = $scope.find('.js-touch-nav__item__toggle');

    $navItemsLinks.on('click', function (evt) {
        if(window.matchMedia('(max-width: 767px)').matches){
            evt.preventDefault();
            evt.stopImmediatePropagation();

            let $item = $(this).closest('.js-touch-nav__item');

            renderChildTemplates($item, '.js-render-navbar-template','.js-render-navbar-template__template');

            if (!$item.hasClass('is-open')) {

                $item.closest('.js-touch-nav__item-wrapper').find('.js-touch-nav__item').removeClass('is-open');
                $item.addClass('is-open');
            } else {
                $item.removeClass('is-open');
            }
        }
    });
}