"use strict";

export function initInScope ($scope) {
    $scope.find('.js-plz-validation__country').on('change',function (e) {
        let countryCode = _config.countryCode;

        let $this = $(this).find('option:selected');
        let value = $(this).find('option:selected').val();
        let $form = $(this).closest('form');
        let $plzInput = $(this).closest('.js-plz-validation').find('.js-plz-validation__plz');
        let $deliveryAddress = $scope.find('#collapseDeliveryAddress');
        let $deliveryAddressInput = $scope.find('.js-check-different-address');
        let $deliveryAddressInputEl = $scope.find('.js-check-different-address input')


        if($deliveryAddress.length) {
            if(value === countryCode) {
                $deliveryAddressInput.attr( "hidden", false );
                $deliveryAddressInputEl.prop( "checked", false );
                $($deliveryAddress).collapse('hide');
            } else {
                $deliveryAddressInput.attr( "hidden", true );
                $($deliveryAddress).collapse('show');
                $deliveryAddressInputEl.prop( "checked", true );
            }
        }


        /* remove attributes */
        $plzInput.removeAttr('pattern');
        $plzInput.attr('data-parsley-error-message',$this.attr('data-parsley-error-msg'));

        if ($this.attr('data-pattern-validation')) {
            /* validate with pattern */
            $plzInput.attr('pattern',$this.attr('data-pattern-validation'));
        }

        /* re validate PLZ input */
        $form.parsley().validate("plz");
    });
}