"use strict";

const selectors = {
    base: '.js-show-collapse-if-checked'
};

export function initInScope ($scope) {
    // shows and hides the element in 'data-show-if-checked' on change
    let $inputs = $scope.find(selectors.base);
    $inputs.on('change', changeHandler);

    // check for other radios without the show-if-checked-class to fire the change event (close other elements)
    $inputs.each(function () {
        let $this = $(this);

        if($this.is(':radio')){
            let $otherRadios = $('input[name="' + $this.attr('name') + '"]').not($this).not(selectors.base);

            $otherRadios.on('change', changeHandler);
        }
    })
}

function changeHandler(evt) {
    let $this = $(this);

    // this part processes all other inputs. So the ones that get automatically unchecked (by the browser) close their targetEl
    if($this.is(':radio')){
        let $otherRadios = $('input[name="' + $this.attr('name') + '"]').not($this);
        $otherRadios.each(function () {
            processInput($(this));
        });
    }

    processInput($this);

}

function processInput($input) {
    let toggleElSelector = $input.data('collapse-target');

    if (toggleElSelector) {
        let $toggleEl = $input.closest('form').find(toggleElSelector);
        if ($toggleEl.length) {
            if ($input.is(':checked')) {
                $($toggleEl).collapse('show');
            } else {
                $($toggleEl).collapse('hide');
            }
        }
    }
}
