"use strict";

export function initInScope ($scope) {
    let $scrollTopBtn =  $('.js-scroll-top');
    $scrollTopBtn.on('click', () =>{
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });

    window.onscroll = function() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            $scrollTopBtn.addClass("scroll-top--show");
            $('body').addClass('scroll-top-visible');

        } else {
            $scrollTopBtn.removeClass("scroll-top--show");
            $('body').removeClass('scroll-top-visible');
        }
    };

}