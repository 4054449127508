"use strict";

const defaultSelectors = {
    nav: '.js-menu-aim',
    item: '.js-menu-aim__item',
    subnav: '.js-menu-aim__subnav'
};

const selectors = {};

export function createInitInScope(selectors = defaultSelectors) {
    return function ($scope) {
        let $nav = $scope.find(selectors.nav),
            $navItems = $scope.find(selectors.item),
            $navLinks = $navItems.children('a');

        if (matchMedia('(min-width: 768px)').matches) {
            let timeout = 100;
            let timer;

            $nav.each(function (e) {
                let $thisNav = $(this),
                    $submenu = $thisNav.find(selectors.subnav),
                    $items = $thisNav.children(selectors.item);

                $items.on('mouseenter', function() {
                    let $element = $(this);
                    clearTimeout(timer);

                    timer = setTimeout(function() {
                        $items.removeClass('is-open');
                        $element.addClass('is-open');
                    }, timeout);

                    // let $renderNavbarContainer = $(this).find('.js-render-navbar-template');
                    //
                    // if($renderNavbarContainer.length){
                    //     let $template = $renderNavbarContainer.find('.js-render-navbar-template__template');
                    //     let templateHtml = $template.html();
                    //
                    //     $renderNavbarContainer.html(templateHtml).removeClass('js-render-navbar-template');
                    //
                    //     $template.remove();
                    //     initModulesInScope($(this));
                    // }

                });

                $items.on('mouseleave', function() {
                    clearTimeout(timer);

                    timer = setTimeout(function() {
                        $items.removeClass('is-open');
                    }, timeout);
                });

                if (window.TouchEvent) {
                    /* open close */
                    $navLinks.on('touchend', function (evt) {
                        if (evt.originalEvent instanceof TouchEvent) {
                            let $item = $(this).closest(selectors.item);

                            if ($item.hasClass('is-open')) {
                                $item.removeClass('is-open');
                            } else {
                                $items.removeClass('is-open');
                                $item.addClass('is-open');
                            }

                            evt.preventDefault();
                        }
                    })

                    /* close on scroll */
                    function scrollDetect(){
                        let $ddItem = $scope.find('.js-menu-aim__item');
                        document.addEventListener('scroll', function(){
                            let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
                            if (currentScroll > 50){
                                $ddItem.removeClass('is-open');
                            }
                        });
                    }
                    scrollDetect();


                }
            });

            $navLinks.on('keydown', function (evt) {
                if(evt.originalEvent.code === 'Space' || evt.originalEvent.code === 'ArrowDown' || evt.originalEvent.code === 'ArrowUp'){
                    evt.preventDefault();
                    $(this).closest(selectors.nav).find(selectors.item).removeClass('is-open');
                    $(this).closest(selectors.item).addClass('is-open');

                    $('body').on('keydown', escKeyHandler);
                } else if (evt.originalEvent.code === 'Tab' && !$(this).parents('.js-nav__item').hasClass('is-open')) {
                    $(selectors.item).removeClass('is-open');
                    $('body').off('keydown', escKeyHandler);
                }
            });
        }
    }
}

function escKeyHandler (evt){
    if(evt.originalEvent.code === 'Escape'){
        $(selectors.nav).removeClass('is-open');
        $('body').off('keydown', escKeyHandler);
    }
}

export const initInScope = createInitInScope();
